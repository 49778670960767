
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.wrapper {
    position: relative;
    @apply px-4;
    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        position: absolute;
        z-index: -1;

        background-color: #fafafa;
    }
    h2 {
        font-size: 20px;
        line-height: 30px;
        font-weight: bold;
        color: $hairstraightener-main-color;
        @include lg {
            font-size: 25px;
            line-height: 37px;
        }
    }
    span {
        width: 70px;
        height: 2px;
        background-color: $hairstraightener-span-color;
        display: block;
    }
    p:nth-of-type(1) {
        margin-top: 25px;
    }
    p {
        font-size: 14px;
        line-height: 27px;
        color: $hairstraightener-paragraph-color;
        margin-top: 15px;
        @include lg {
            font-size: 18px;
            line-height: 32px;
        }
    }
}
